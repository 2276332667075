import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS, Flex, Grid, SxProps, Typography } from '@dripcapital/dripui';
import { Email } from '@mui/icons-material';

type AgentProps = {
  name?: string;
  email?: string;
};

const TalkToAgentCard: React.FC<AgentProps> = ({
  email = 'xxxxxwis@dripcapital.com',
}: AgentProps) => {
  const { t } = useTranslation();

  const style: SxProps = {
    padding: 0.8,
    color: COLORS.SECONDARY,
    fontSize: 14,
  };

  return (
    <Grid container dataTestId="talk-to-agent-card">
      <Grid xs={12} _sx={style} item>
        <Flex alignItems={'center'} direction="row">
          <Typography
            variant="h5"
            fontWeight="bold"
            _sx={{ color: COLORS.SECONDARY }}
          >
            {t('label_contact_agent')}
          </Typography>
        </Flex>
      </Grid>
      <Grid xs={12} _sx={style} item>
        <Flex alignItems={'center'} direction="row">
          <Email sx={{ marginRight: 1 }} />
          <a
            href={`mailto:${email}`}
            style={{
              color: COLORS.PRIMARY,
              wordBreak: 'break-all',
            }}
          >
            {email}
          </a>
        </Flex>
      </Grid>
      <Grid xs={12} _sx={style} item>
        <Flex alignItems={'center'} direction="row">
          <Typography variant="body2">
            <Typography
              variant="body2"
              fontWeight="bold"
              _sx={{ display: 'inline' }}
            >
              {t('label_contact_agent_note')}
            </Typography>
            <Typography variant="body2" _sx={{ display: 'inline' }}>
              {t('label_contact_agent_note_info1')}
            </Typography>
            <a
              href={`mailto:${email}`}
              style={{
                color: COLORS.PRIMARY,
                wordBreak: 'break-all',
              }}
            >
              {t('label_contact_agent_email')}
            </a>
            <Typography variant="body2" _sx={{ display: 'inline' }}>
              {t('label_contact_agent_note_info2')}
            </Typography>
          </Typography>
        </Flex>
      </Grid>
    </Grid>
  );
};

export default TalkToAgentCard;
