import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import {
  Box,
  COLORS,
  Flex,
  Paper,
  Typography,
  Grid,
  CircularProgress,
  LinearProgress,
  FormHelperText,
  useMediaQuery,
  useTheme,
} from '@dripcapital/dripui';
import { saveAs } from 'file-saver';
import {
  Close,
  DeleteOutlineOutlined,
  DownloadOutlined,
  FolderOpen,
} from '@mui/icons-material';
import { File as UploadFile, UploadDocument, useRemoveFileMutation } from 'api';
import { Spacer, AtomicButton, Modal } from 'atoms';
import { ButtonTypeEnum, ProgressTypeEnum } from 'enums';
import {
  DAJ_COLORS,
  handleError,
  humanFileSize,
  renameFile,
  TOKEN,
} from 'utils';
import { useReactiveVar } from '@apollo/client';
import { appDataVar } from 'config/server';

type Props = {
  buttonTitle: string;
  handleChange?: () => void;
  fileLimit: number;
  multiple: boolean;
  maxFiles?: number;
  progressType: ProgressTypeEnum;
  removeSection?: () => void;
  refetch?: () => void;
  subtitle?: string;
  supportedFiles: string[];
  title: string;
  list?: string[];
  uploads?: UploadFile[];
  url: string;
  tags?: object[];
  setLoading?: (flag: boolean) => void;
  trackEvent?: () => void;
  onClose?: () => void;
  spacing?: boolean;
  uploadError?: string;
  disabled?: boolean;
};

const Upload: React.FC<Props> = ({
  title,
  subtitle,
  list,
  buttonTitle,
  handleChange,
  multiple,
  maxFiles = 1,
  progressType,
  uploads,
  fileLimit,
  url,
  refetch,
  supportedFiles,
  tags,
  setLoading,
  trackEvent,
  onClose,
  spacing = false,
  uploadError = '',
  disabled = false,
}: Props) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const mdLimit = useMediaQuery(theme.breakpoints.down('md'));

  const lgLimit = useMediaQuery(theme.breakpoints.down('lg'));

  const [cookies] = useCookies([TOKEN]);

  const {
    applicantId,
    businessId,
    isChannelPartnerVar: isCP,
  } = useReactiveVar(appDataVar);

  const [removeFileTag] = useRemoveFileMutation();

  const [isRemoveDialogOpen, setRemoveDialog] = useState(false);

  const [currentUpload, setCurrentUpload] = useState<UploadFile>({});

  const [progress, setProgress] = useState(0);

  const [error, setError] = useState(uploadError);

  useEffect(() => {
    setError(uploadError);
  }, [uploadError]);

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files) {
      trackEvent && trackEvent();
      const fileUploaded = Array.from(e.target.files);
      const file = renameFile(
        fileUploaded[0],
        fileUploaded[0]?.name?.replaceAll(' ', '_')
      );
      const fileExtension = file?.name?.split('.')?.pop() || '';
      const fileSupport = supportedFiles.includes(fileExtension?.toLowerCase());
      if (!fileSupport || file.size > fileLimit) {
        setError(
          t('phrase_file_upload_rule', {
            sizeLimit: humanFileSize(fileLimit, true, 0),
          })
        );
      } else {
        setError('');
        handleChange?.();
        upload(file);
      }
    }
  };

  const handleFilesSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target?.files) {
      trackEvent?.();
      const filesUploaded = Array.from(e.target?.files);
      const underscoredFiles = filesUploaded.map((file) =>
        renameFile(file, file?.name?.replaceAll(' ', '_'))
      );
      underscoredFiles.forEach((file) => {
        const fileExtension = file?.name?.split('.')?.pop() || '';
        const fileSupport = supportedFiles.includes(
          fileExtension?.toLowerCase()
        );
        if (!fileSupport || file.size > fileLimit) {
          setError(
            t('phrase_file_upload_rule', {
              sizeLimit: humanFileSize(fileLimit, true, 0),
            })
          );
          handleError(
            t('phrase_file_upload_rule', {
              sizeLimit: humanFileSize(fileLimit, true, 0),
            }),
            {},
            8000
          );
        } else {
          setError('');
          handleChange?.();
          upload(file);
        }
      });
    }
  };

  const download = (upload: UploadFile) => {
    saveAs(upload?.url || '', upload?.name || '');
  };

  const removeFile = async (upload: UploadFile) => {
    setLoading?.(true);
    setRemoveDialog(false);
    try {
      removeFileTag({
        variables: {
          input: {
            documentId: upload.documentId || '',
            id: isCP ? businessId : applicantId,
          },
        },
        onError: () => {
          setRemoveDialog(false);
          handleError(t('title_file_remove_message'));
        },
      });
    } catch (e) {
      handleError(t('title_file_remove_message'));
    }
    refetch?.();
    setLoading?.(false);
  };

  const upload = async (file: File) => {
    setLoading?.(true);
    const headers = {
      Authorization: `Bearer ${cookies[TOKEN]}`,
    };
    const formData = new FormData();
    if (tags && tags.length) {
      tags.forEach((tag: any) => {
        formData.append(tag?.key, tag.value);
      });
    }
    formData.append('file', file);
    try {
      await UploadDocument(url, 'POST', headers, formData, uploadProgress);
    } catch (e) {
      handleError(t('title_file_error_message'));
    }
    await refetch?.();
    setLoading?.(false);
  };

  const uploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    setProgress(percentCompleted);
  };

  const renderUploads = () => {
    return (
      <>
        {uploads &&
          uploads.map((upload, index) => (
            <React.Fragment key={index}>
              <Grid container dataTestId="download-button">
                <Grid item xs={12} lg={6} xl={8}>
                  <Flex alignItems="center">
                    <FolderOpen
                      sx={{ marginRight: 1, color: COLORS.SECONDARY }}
                    />
                    <Typography
                      noWrap
                      _sx={{ maxWidth: mdLimit ? '180px' : '250px' }}
                    >
                      {upload?.name}
                    </Typography>
                  </Flex>
                </Grid>
                <Grid item xs={12} lg={3} xl={2}>
                  <Flex
                    alignItems="center"
                    justifyContent={lgLimit ? 'normal' : 'flex-end'}
                    _sx={{ cursor: 'pointer', marginRight: 1 }}
                    onClick={() => download(upload)}
                  >
                    <DownloadOutlined
                      sx={{ marginLeft: 1, color: COLORS.PRIMARY }}
                    />
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      fontWeight="bold"
                    >
                      {t('btn_download')}
                    </Typography>
                  </Flex>
                </Grid>
                <Grid item xs={12} lg={3} xl={2}>
                  <Flex
                    alignItems="center"
                    justifyContent={lgLimit ? 'normal' : 'flex-end'}
                    _sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setCurrentUpload(upload);
                      setRemoveDialog(true);
                    }}
                  >
                    <DeleteOutlineOutlined
                      sx={{ marginLeft: 1, color: COLORS.PRIMARY }}
                    />
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      fontWeight="bold"
                    >
                      {t('btn_remove')}
                    </Typography>
                  </Flex>
                </Grid>
              </Grid>
              <Modal
                dataTestId="person-info-modal"
                isOpen={isRemoveDialogOpen}
                closeDialog={() => setRemoveDialog(false)}
                title={t('phrase_remove_file')}
                content={t('phrase_remove_file_content')}
                buttons={[
                  {
                    dataTestId: 'accept-button',
                    title: t('btn_remove'),
                    handler: () => removeFile(currentUpload),
                    variant: ButtonTypeEnum.CONTAINED,
                    _sx: { padding: theme.spacing(1.3) },
                  },
                  {
                    dataTestId: 'deny-button',
                    title: t('btn_cancel'),
                    handler: () => setRemoveDialog(false),
                    variant: ButtonTypeEnum.OUTLINED,
                    _sx: { padding: theme.spacing(1.3) },
                  },
                ]}
                _sx={{ padding: theme.spacing(3), maxWidth: 500 }}
              />
              {index !== uploads.length - 1 && <Spacer verticalSpace={2} />}
            </React.Fragment>
          ))}
      </>
    );
  };

  const renderProgress = () => {
    switch (progressType) {
      case ProgressTypeEnum.CIRCULAR:
        return (
          <Box
            _sx={{
              position: 'relative',
              display: 'inline-flex',
              marginLeft: 1,
            }}
          >
            <CircularProgress
              variant="determinate"
              value={progress}
              size={42}
            />
            <Box
              _sx={{
                width: 40,
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="body1" color="primary">
                {progress && progress > 0 ? progress : null}
              </Typography>
            </Box>
          </Box>
        );
      case ProgressTypeEnum.LINEAR:
        return (
          <>
            <Spacer verticalSpace={1} />
            <LinearProgress variant="determinate" value={progress} />
          </>
        );
    }
  };

  return (
    <Paper
      dataTestId="file-upload-element"
      variant="outlined"
      elevation={0}
      _sx={{
        position: 'relative',
        paddingY: 2,
        paddingX: 2,
        borderRadius: '8px',
        borderColor: disabled
          ? DAJ_COLORS.NEUTRAL_DARK_GREY
          : DAJ_COLORS.SKY_BLUE_400,
      }}
    >
      <Box id="upload-atoms">
        {uploads && uploads?.length == 0 && onClose && (
          <Close
            onClick={onClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              cursor: 'pointer',
              color: DAJ_COLORS.GREEN,
            }}
          />
        )}
        <Typography variant="h4" color="secondary" fontWeight="bold">
          {title}
        </Typography>
        {subtitle && <Spacer verticalSpace={1} />}
        {subtitle && <Typography variant="body1">{subtitle}</Typography>}
        {spacing && <Spacer verticalSpace={3} />}
        {list && <Spacer verticalSpace={2} />}
        {list &&
          Array.from(list) &&
          list.map((item, index) => {
            return (
              <Typography key={index} variant="body1">
                <li>{item}</li>
              </Typography>
            );
          })}
        {!!uploads?.length && <Spacer verticalSpace={2} />}
        {renderUploads()}
        {buttonTitle && (
          <Flex _sx={{ mt: theme.spacing(2) }}>
            <AtomicButton
              variant={ButtonTypeEnum.OUTLINED}
              disabled={
                disabled ||
                (progress && progress > 0 && progress < 100) ||
                (uploads && uploads.length >= maxFiles)
                  ? true
                  : false
              }
              _sx={{
                visibility: 'initial',
                paddingX: 3,
                paddingY: 1,
              }}
              onClick={() => 1}
              component={'label'}
            >
              {buttonTitle}
              <input
                id="files"
                type="file"
                onChange={multiple ? handleFilesSelected : handleFileSelected}
                multiple={multiple}
                autoComplete="off"
                hidden
              />
            </AtomicButton>
            {progress && progress > 0 && progress < 100
              ? renderProgress()
              : undefined}
          </Flex>
        )}
        {error && (
          <FormHelperText sx={{ color: DAJ_COLORS.RED }}>
            {t(`${error}`)}
          </FormHelperText>
        )}
      </Box>
    </Paper>
  );
};

export default Upload;
